import axios from "axios";

export default {
    state:{
        establecimiento:{
            nombrecomercial:'CONTABILIDAD EYG',
            codigo:'1',
            propietario:'JUAN JOSE PAREDES',
            nit:'123456789',
            direccion:'AVENIDA CHIPILAPA ENTRE 2DA CALLE Y 3RA CALLE ZONA 1',
            codigopostal:'21001',
            municipio:'GUASTATOYA',
            departamento:'EL PROGRESO',
            codregimen:'GEN'
        },

        items:[],
        selecteditem:'',
    },
    mutations:{
        setItems(state,items){
            items.length>0?state.items = items:state.items=[]          
            state.selecteditem=''
        },
        setSelectedItem(state,item){
            state.selecteditem = item
        }
    },
    actions:{
        getItems({commit,rootState,},IdRegimen){
            const token = rootState.Usuario.token
            axios.get(`${rootState.urlapi}/establecimientos/${IdRegimen}`,{headers:{'auth-user':token}})
            .then(response=>{           
                    commit("setItems",response.data)                 
            })     
        },
        setSelectedItem({commit,state},idestablecimiento){
            const item = state.items.find(elemento=>elemento.IdEstablecimiento==idestablecimiento)
            commit("setSelectedItem",item)
        }
    },
    getters:{
        getItems(state){
            return state.items
        },
        getSelectedItem(state){
            return state.selecteditem//items.find(elemento=>elemento.Nit==nit)
        },
        getItemsActivos(state){
            return state.items.filter(elemento=>elemento.Estado=='Activo')
        },
        getEstablecimiento(state){
            return state.establecimiento
        }
    }
}